<template>
  <lightning-template
    :wishes-lightening="wishesLightening"
    :field-errors="fieldErrors"
    @field-change="onFieldChange"
  />
</template>

<script>
import LightningTemplate from '@/modules/questionnaire/components/steps/questionnaire/skin-pigmentation/lightning/LightningTemplate';
import { makeStep, resetStoreValues } from '@/modules/questionnaire/mixins';

import { fieldBuilder } from '@/modules/questionnaire/mixins/makeStep';

import { no } from '@/modules/questionnaire/api/helpers';

const { requiredField, field } = fieldBuilder;

const fieldsToReset = ['hyperpigmentationDiscomfort'];

const FIELDS = [...fieldsToReset.map(field), requiredField('wishesLightening')];

export default {
  name: 'Lightning',
  components: {
    LightningTemplate
  },
  mixins: [makeStep(FIELDS), resetStoreValues],
  watch: {
    wishesLightening(newValue) {
      if (no(newValue)) {
        this.resetStoreFieldsByName(fieldsToReset);
      }

      this.showNextStep();
    }
  }
};
</script>
